import React from 'react';
import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  Button
} from "@mui/material";
import HeroCarousel from './HeroCarousel';

const imgUrls = [
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Pool_556f313566_196d5d3d4c.jpg?updated_at=2024-11-18T21:45:35.337Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Master_bed_9922460df8_5773d0750a.jpg?updated_at=2024-11-18T21:45:35.786Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Kitchen_island_2_82f1f2a26d_01c5df48ff.jpg?updated_at=2024-11-18T21:45:35.679Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Fire_place_1_f361de420a_eaf944c1c5.jpg?updated_at=2024-11-18T21:45:36.320Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/1st_living_area_b885e0e106_490ffdfd75.jpg?updated_at=2024-11-18T21:45:36.460Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Hallway_full_bath_450acab97d_e6912f26da.jpg?updated_at=2024-11-18T21:45:35.841Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Soaking_Tub_bb3e9971ee_496239f930.jpg?updated_at=2024-11-18T21:45:36.882Z'
];

const PlaceOfZen = ({ loaded }) => {
  return loaded ? (
    <Box w="100%">
      <Box display="flex" justifyContent="center">
        <Grid container maxWidth={1000}>
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <HeroCarousel imgUrls={imgUrls} />
          </Grid>
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box p="20px">
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 700,
                  lineHeight: "60px",
                  textTransform: "uppercase",
                }}
              >
                PLACE OF ZEN
              </Typography>
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
                sx={{ whiteSpace: "pre-line" }}
              >
                Experience our Serene Japandi Retreat, a luxurious fusion of Japanese & Scandinavian design. Unwind in this spa-inspired haven, featuring an indoor pool, soaking tub, sauna, & rain showers. Embrace the calming space, adorned with minimalist furniture, clean lines & natural materials. Discover Zen-like balance & harmony, perfect for a rejuvenating escape. Book now to enjoy tranquility & luxurious spa amenities in this exquisite Airbnb.
              </Typography>
            </Box>

            <Box p="20px">
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 700,
                  lineHeight: "60px",
                  textTransform: "uppercase",
                }}
              >
                The Space
              </Typography>
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 400,
                  lineHeight: "24px",
                  marginBottom: "50px"
                }}
                sx={{ whiteSpace: "pre-line" }}
              >
                Welcome to our enchanting Japanese-Scandinavian-inspired home. This meticulously designed retreat seamlessly blends the elegance of Japanese aesthetics with the simplicity of Scandinavian design. As you step inside, you'll be greeted by the warmth of not just one, but two fireplaces, creating a cozy and inviting atmosphere throughout the space.
                <br />
                <br />
                The heart of this home is the pristine white kitchen island, featuring fully functional appliances that cater to all your culinary needs. Whether you're preparing a delicious meal or enjoying a casual breakfast, the kitchen offers both style and functionality, making cooking a pleasurable experience.
                <br />
                <br />
                The rooms are spacious, providing ample room for relaxation and rejuvenation. Each room is unique and thoughtfully furnished, some rooms come with a working table, allowing you to comfortably catch up on work or engage in creative pursuits. Unwind in the tranquil ambiance of these rooms, finding the perfect balance between productivity and relaxation.
                <br />
                <br />
                For ultimate relaxation, indulge in the indoor pool, a serene haven where you can let go of all worries and simply unwind. The pool offers a tranquil space to rejuvenate your body and mind, providing a refreshing escape from the outside world.
                <br />
                <br />
                In the backyard, you'll discover a BBQ area, perfect for bonding with your family. Spend quality time together, savoring delicious grilled meals and creating lasting memories. The backyard provides a private sanctuary where you can enjoy the beautiful surroundings and connect with your loved ones.
                <br />
                <br />
                We offer a haven where you can unwind, reconnect with your family, and create cherished moments.
              </Typography>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "50px",
                    textTransform: "capitalize",
                    color: 'white',
                    backgroundColor: 'rgb(38, 128, 56)',
                    marginBottom: '80px'
                  }}
                  href="https://www.airbnb.com/rooms/645014660235873830"
                  target='_blank'

                >
                  Book Now
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : null;
};

export default PlaceOfZen;
